<template>
  <div class="">
    <AppLoader v-if="isLoading" class="text-white"></AppLoader>
    <div v-if="!isLoading">
      <div class="relative px-4 md:mt-12 xl:mt-16 w-full -mr-24 sm:mr-0">
        <h1 class="text-center font-ubuntu pb-12">
          <span class="block text-white font-light text-shadow text-3xl sm:text-4xl lg:text-5xl pb-5">You’re on the waitlist to become a PhotoSapien</span>
          <span class="block text-white font-light text-shadow text-xl lg:text-2xl whitespace-pre-wrap">
                You are currently <span class="font-ubuntu font-bold text-primary-300">{{ memberState.queue }}</span> in the queue.<br>
                To jump 10 places in the queue, invite your friends to
            </span>
        </h1>
        <div class="px-4">
          <div class="flex items-center justify-center transparent-block text-center rounded-md px-6 sm:px-8 pt-5 pb-5">
            <div class="w-full">
              <div class="text-white text-lg md:text-xl lg:text-2xl pt-0 pb-4">
                Share your link
              </div>
              <div class="text-white text-md pt-0 pb-4">
                Shares: <span class="font-ubuntu font-bold text-primary-300">{{ memberState.shareViews }}</span>
              </div>
              <div class="sm:mx-auto sm:max-w-2xl">
                <div class="sm:flex mb-3">
                  <div class="min-w-0 flex-1  hidden">
                    <div class="custom-input">
                      <div class="text-center w-full leading-8">
                        {{ shareLink }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 sm:mt-0 sm:ml-3 w-full">
                    <div class=" flex justify-center">
                      <button class="btn-content" @click="clipboardCopy">
                        <LinkIcon class="inline mr-2 h-7 w-7"></LinkIcon>
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
                <div class="pt-3">
                  <div class="flex justify-center">
                    <a :href="tweetLink" target="_blank" class="mx-2"><img class="" src="../assets/images/svg/twitter_icon.svg" alt=""/></a>
                    <a :href="fbLink" target="_blank" class="mx-2"><img class="" src="../assets/images/svg/facebook_icon.svg" alt=""/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LinkIcon } from '@heroicons/vue/solid'
import { computed, inject, onMounted, ref } from "vue";
import AppLoader from "@/components/AppLoader";


const emitter = require('tiny-emitter/instance');

export default {
  components: { AppLoader, LinkIcon },
  props: {},
  setup()
  {
    const memberState = inject('memberState')
    const isLoading = ref(true)

    const shareLink = computed(() => {

      let getUrl = window.location;
      let baseUrl = getUrl.protocol + "//" + getUrl.host + "/"

      return baseUrl + memberState.shareCode
    })

    const copyURL = async (mytext) => {
      try {
        await navigator.clipboard.writeText(mytext);
      } catch ($e) {
        console.log('error on copy');
      }
    }

    const clipboardCopy = async () => {
      await copyURL(shareLink.value)
      emitter.emit('fire-alert', 'Copied', 'success')
    }

    const tweetLink = computed(() => {
      let text = encodeURI('I recommend checking out @photo_chromic which has an NFT premint whitelist accessible here ' + shareLink.value)

      return 'https://twitter.com/intent/tweet?text=' + text
    })


    const fbLink = computed(() => {
      return 'https://www.facebook.com/sharer/sharer.php?u=' + shareLink.value
    })

    onMounted(() => {
      if (!memberState.isValidated) {
        console.log(shareLink);
      }
      isLoading.value = false

    })

    return { memberState, shareLink, clipboardCopy, tweetLink, fbLink, isLoading }
  }
}
</script>